var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderNow
    ? _c(
        "div",
        [
          _c("the-top-bar", {
            attrs: { services: _vm.services },
            on: { logout: _vm.logout },
          }),
          _vm.isTeacher
            ? _c("teacher-app-navigation-drawer", {
                on: { collapse: _vm.collapseHandler, logout: _vm.logout },
              })
            : _vm.isStudent
            ? _c("student-app-navigation-drawer", {
                on: { collapse: _vm.collapseHandler, logout: _vm.logout },
              })
            : _vm._e(),
          _c(
            "v-content",
            { style: _vm.styles.content[_vm.collapsedStyle] },
            [_c("router-view")],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }