<template v-if="renderNow">
  <v-app-bar
    color="#ffffff"
    class="m-app-bar align-center"
    clipped-left
    app
    fixed
    flat
  >
    <img
      v-show="!hideMouchakLogo"
      height="49"
      width="79"
      :src="require('@/assets/ielts/ielts-logo.svg')"
      class="clickable"
      style="margin-left: 64px;"
      alt="IELTS Logo"
      @click="$router.push('/')"
    />
  </v-app-bar>
</template>

<script>
import authService from "@af/modules/auth/services/AuthService";
import store from "@af/app-shell/store";

export default {
  name: "TheTopBarAccounts",
  props: {
    services: {
      type: Array,
      required: true
    }
  },
  async created() {
    this.renderNow = true;
    await this.getUser();
  },
  data() {
    return {
      renderNow: false,
      user: undefined
    };
  },
  computed: {
    userFromStore() {
      return this.$store.getters[this.$store.$types.GET_USER_DETAILS];
    },
    isTeacher() {
      if (this.userFromStore.authorized_views.includes("TEACHER_VIEW")) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    async getUser() {
      // return this.$store.getters[this.$store.$types.GET_USER_DETAILS];
      this.user = await authService.getUser(store.state.auth.token);
    },
    goToHome() {
      this.$router.push("/");
    }
  }
};
</script>

<style scoped></style>
