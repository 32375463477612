<template>
  <div v-if="renderNow">
    <the-top-bar @logout="logout" :services="services"></the-top-bar>
    <teacher-app-navigation-drawer
      v-if="isTeacher"
      @collapse="collapseHandler"
      @logout="logout"
    ></teacher-app-navigation-drawer>
    <student-app-navigation-drawer
      v-else-if="isStudent"
      @collapse="collapseHandler"
      @logout="logout"
    ></student-app-navigation-drawer>
    <v-content :style="styles.content[collapsedStyle]">
      <router-view></router-view>
    </v-content>
  </div>
</template>

<script>
import TheTopBar from "@af/app-shell/components/TheTopBarAccounts";
import StudentAppNavigationDrawer from "../components/StudentAppNavigationDrawer.vue";
import TeacherAppNavigationDrawer from "@af/app-shell/components/TeacherAppNavigationDrawer.vue";
import authService from "@af/modules/auth/services/AuthService";
import { expireAuthCookie, expireRoleCookie } from "/global/utils/helpers";

export default {
  name: "Authenticated",
  components: {
    StudentAppNavigationDrawer,
    TheTopBar,
    TeacherAppNavigationDrawer
  },
  // async created() {
  //   await this.getUser();
  // },
  created() {
    this.renderNow = true;
  },
  data: () => ({
    renderNow: false,
    services: [],
    collapsedStyle: false
  }),
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    userFromStore() {
      return this.$store.getters[this.$store.$types.GET_USER_DETAILS];
    },
    isTeacher() {
      if (this.userFromStore.authorized_views.includes("TEACHER_VIEW")) {
        return true;
      } else {
        return false;
      }
    },
    isStudent() {
      if (this.userFromStore.authorized_views.includes("STUDENT_VIEW")) {
        return true;
      } else {
        return false;
      }
    },
    styles() {
      return {
        content: {
          true: "padding-left: 56px",
          false: ""
        }
      };
    }
  },
  methods: {
    logout() {
      expireAuthCookie();
      expireRoleCookie();
      window.location.href = process.env.VUE_APP_FRONTEND_URL;
    },
    collapseHandler(payload) {
      let s = this.screenWidth;
      if (s < 600) {
        this.collapsedStyle = true;
      } else if (s < 801 && payload) {
        this.collapsedStyle = true;
      } else {
        this.collapsedStyle = false;
      }
    }
  }
};
</script>
