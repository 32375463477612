<template>
  <v-app class="m-site">
    <v-app-bar
      fixed
      elevate-on-scroll
      color="#fff"
      scroll-target="#ielts-content"
      height="80"
      style="background: white"
    >
      <div style="width: 100%">
        <v-row align="center" justify="space-between">
          <v-col style="margin-left: 136px;" class="pl-0">
            <img
              height="38"
              width="78"
              class="clickable"
              :src="require('@/assets/ielts/ielts-logo.svg')"
              alt="IELTS Logo"
              @click="$router.push('/')"
              @click.self="scrollToTop"
            />
          </v-col>
          <v-col class="d-flex align-center justify-center">
            <div
              class="clickable"
              @click="$router.push('/'), scrollTo('ielts-features')"
            >
              Features
            </div>
            <div
              class="ml-8 clickable"
              @click="$router.push('/'), scrollTo('ielts-getting-started')"
            >
              Getting Started
            </div>
            <div
              class="ml-8 clickable"
              @click="$router.push('/'), scrollTo('ielts-about')"
            >
              About
            </div>
          </v-col>
          <v-col class="d-flex justify-end pr-0" style="margin-right: 136px">
            <div v-if="!doesSessionExist">
              <v-btn
                height="45"
                width="120"
                outlined
                @click="$router.push({ path: '/auth/signin' })"
              >
                Log In
              </v-btn>
              <v-btn
                class="ml-4 white--text"
                height="45"
                color="primary"
                depressed
                width="180"
                @click="$router.push({ path: '/auth/register' })"
              >
                Start Free Trial
              </v-btn>
            </div>
            <div v-else>
              <v-btn
                height="45"
                width="120"
                outlined
                @click="$router.push({ path: userPath })"
              >
                Enter
              </v-btn>
              <v-menu
                open-on-hover
                bottom
                offset-y
                transition="slide-y-transition"
              >
                <template #activator="{on}">
                  <v-btn
                    class="ml-4 white--text"
                    height="45"
                    color="primary"
                    depressed
                    width="180"
                    v-on="on"
                  >
                    My Account
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list class="">
                  <v-list-item
                    class="d-flex justify-center align-center"
                    to="/accounts"
                  >
                    <v-icon class="mr-2">mdi-account-circle</v-icon>
                    <span>View profile</span>
                  </v-list-item>
                  <v-list-item
                    class="d-flex justify-center align-center"
                    @click="logout()"
                  >
                    <v-icon class="mr-2">mdi-location-exit</v-icon>
                    <span>Logout</span></v-list-item
                  >
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-app-bar>
    <v-main id="ielts-content">
      <router-view></router-view>
    </v-main>
    <!-- Footer section -->
    <v-row no-gutters justify="center" align="center" style="height: 100%">
      <v-img :src="require('@/assets/ielts/Footer.svg')">
        <v-row class="pt-12" no-gutters justify="center" align="center">
          <img
            height="76"
            width="156"
            :src="require('@/assets/ielts/ielts-logo-white.svg')"
          />
        </v-row>
        <v-row
          no-gutters
          justify="center"
          align="center"
          style="margin-top: 50px;"
        >
          <v-icon color="white" class="mr-9">mdi-facebook</v-icon>
          <v-icon color="white" class="mr-9">mdi-instagram</v-icon>
          <v-icon color="white">mdi-linkedin</v-icon>
        </v-row>
        <v-row
          no-gutters
          justify="center"
          align="center"
          style="margin-top: 70px; margin-bottom: 23px;"
        >
          <hr style=" width: 80%; color: #fff" />
        </v-row>
        <v-row no-gutters justify="center" align="center">
          <p class="white--text">
            © Copyright Mouchak Education 2023. Designed & Developed by Brick
            Line Technology Limited.
          </p>
        </v-row>
      </v-img>
    </v-row>
  </v-app>
</template>

<script>
import { expireAuthCookie, expireRoleCookie } from "/global/utils/helpers";

export default {
  name: "IeltsFrontend",
  data() {
    return {
      scrolling: false
    };
  },
  computed: {
    doesSessionExist() {
      return this.$store.state.user.authToken !== undefined;
    },
    isTeacher() {
      return this.$store.state.role.includes("TEACHER_VIEW");
    },
    isStudent() {
      return this.$store.state.role.includes("STUDENT_VIEW");
    },
    userPath() {
      return this.isTeacher
        ? "/teachers/"
        : this.isStudent
        ? "/students/"
        : "/";
    }
  },
  methods: {
    makeScroll() {
      this.scrolling = true;
    },
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        top: 0,
        behavior: "smooth"
      });
    },
    scrollToTop() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    logout() {
      expireAuthCookie();
      expireRoleCookie();
      window.location.href = process.env.VUE_APP_FRONTEND_URL;
    }
  }
};
</script>

<style lang="scss" scoped>
.m-site {
  background-color: #fff !important;
}
</style>
