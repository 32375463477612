var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "m-site" },
    [
      _c(
        "v-app-bar",
        {
          staticStyle: { background: "white" },
          attrs: {
            fixed: "",
            "elevate-on-scroll": "",
            color: "#fff",
            "scroll-target": "#ielts-content",
            height: "80",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "v-row",
                { attrs: { align: "center", justify: "space-between" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pl-0",
                      staticStyle: { "margin-left": "136px" },
                    },
                    [
                      _c("img", {
                        staticClass: "clickable",
                        attrs: {
                          height: "38",
                          width: "78",
                          src: require("@/assets/ielts/ielts-logo.svg"),
                          alt: "IELTS Logo",
                        },
                        on: {
                          click: [
                            function ($event) {
                              return _vm.$router.push("/")
                            },
                            function ($event) {
                              if ($event.target !== $event.currentTarget)
                                return null
                              return _vm.scrollToTop.apply(null, arguments)
                            },
                          ],
                        },
                      }),
                    ]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "d-flex align-center justify-center" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clickable",
                          on: {
                            click: function ($event) {
                              _vm.$router.push("/"),
                                _vm.scrollTo("ielts-features")
                            },
                          },
                        },
                        [_vm._v(" Features ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "ml-8 clickable",
                          on: {
                            click: function ($event) {
                              _vm.$router.push("/"),
                                _vm.scrollTo("ielts-getting-started")
                            },
                          },
                        },
                        [_vm._v(" Getting Started ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "ml-8 clickable",
                          on: {
                            click: function ($event) {
                              _vm.$router.push("/"), _vm.scrollTo("ielts-about")
                            },
                          },
                        },
                        [_vm._v(" About ")]
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-end pr-0",
                      staticStyle: { "margin-right": "136px" },
                    },
                    [
                      !_vm.doesSessionExist
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    height: "45",
                                    width: "120",
                                    outlined: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.push({
                                        path: "/auth/signin",
                                      })
                                    },
                                  },
                                },
                                [_vm._v(" Log In ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-4 white--text",
                                  attrs: {
                                    height: "45",
                                    color: "primary",
                                    depressed: "",
                                    width: "180",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.push({
                                        path: "/auth/register",
                                      })
                                    },
                                  },
                                },
                                [_vm._v(" Start Free Trial ")]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    height: "45",
                                    width: "120",
                                    outlined: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.push({
                                        path: _vm.userPath,
                                      })
                                    },
                                  },
                                },
                                [_vm._v(" Enter ")]
                              ),
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "open-on-hover": "",
                                    bottom: "",
                                    "offset-y": "",
                                    transition: "slide-y-transition",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "ml-4 white--text",
                                                attrs: {
                                                  height: "45",
                                                  color: "primary",
                                                  depressed: "",
                                                  width: "180",
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(" My Account "),
                                              _c("v-icon", [
                                                _vm._v("mdi-chevron-down"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c(
                                    "v-list",
                                    {},
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass:
                                            "d-flex justify-center align-center",
                                          attrs: { to: "/accounts" },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-2" },
                                            [_vm._v("mdi-account-circle")]
                                          ),
                                          _c("span", [_vm._v("View profile")]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass:
                                            "d-flex justify-center align-center",
                                          on: {
                                            click: function ($event) {
                                              return _vm.logout()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-2" },
                                            [_vm._v("mdi-location-exit")]
                                          ),
                                          _c("span", [_vm._v("Logout")]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("v-main", { attrs: { id: "ielts-content" } }, [_c("router-view")], 1),
      _c(
        "v-row",
        {
          staticStyle: { height: "100%" },
          attrs: { "no-gutters": "", justify: "center", align: "center" },
        },
        [
          _c(
            "v-img",
            { attrs: { src: require("@/assets/ielts/Footer.svg") } },
            [
              _c(
                "v-row",
                {
                  staticClass: "pt-12",
                  attrs: {
                    "no-gutters": "",
                    justify: "center",
                    align: "center",
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      height: "76",
                      width: "156",
                      src: require("@/assets/ielts/ielts-logo-white.svg"),
                    },
                  }),
                ]
              ),
              _c(
                "v-row",
                {
                  staticStyle: { "margin-top": "50px" },
                  attrs: {
                    "no-gutters": "",
                    justify: "center",
                    align: "center",
                  },
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-9", attrs: { color: "white" } },
                    [_vm._v("mdi-facebook")]
                  ),
                  _c(
                    "v-icon",
                    { staticClass: "mr-9", attrs: { color: "white" } },
                    [_vm._v("mdi-instagram")]
                  ),
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v("mdi-linkedin"),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticStyle: {
                    "margin-top": "70px",
                    "margin-bottom": "23px",
                  },
                  attrs: {
                    "no-gutters": "",
                    justify: "center",
                    align: "center",
                  },
                },
                [_c("hr", { staticStyle: { width: "80%", color: "#fff" } })]
              ),
              _c(
                "v-row",
                {
                  attrs: {
                    "no-gutters": "",
                    justify: "center",
                    align: "center",
                  },
                },
                [
                  _c("p", { staticClass: "white--text" }, [
                    _vm._v(
                      " © Copyright Mouchak Education 2023. Designed & Developed by Brick Line Technology Limited. "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }