<template>
  <v-card>
    <!-- removing  :mini-variant.sync="mini" for getting all collapsed icon in mini version -->
    <v-navigation-drawer
      v-model="drawer"
      fixed
      clipped
      app
      floating
      :class="['mk-navigation-drawer', { sidenav: mini }]"
      :style="
        $vuetify.breakpoint.smAndDown
          ? 'padding: 6px; padding-left: 8px;'
          : 'padding: 12px;'
      "
    >
      <slot name="drawer-items">
        <v-layout justify-space-between column fill-height>
          <v-list
            subheader
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'margin-top: 11px'
                : 'margin-top: 38px'
            "
          >
            <v-list-item v-if="$vuetify.breakpoint.smAndDown">
              <v-list-item-icon style="margin-left: -6px">
                <v-app-bar-nav-icon
                  v-if="mini"
                  @click="mini = !mini"
                ></v-app-bar-nav-icon>
                <v-icon
                  v-else
                  style="margin-left: 4px"
                  color="orange"
                  @click="mini = !mini"
                  >mdi-close
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="xl:text-sm lg:text-sm md:text-sm sm:text-sm ma-0"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item exact to="/students/home" style="padding-left: 25px">
              <v-list-item-icon style="margin-right: 16px">
                <v-img
                  v-if="currentRoute !== '/students/home'"
                  :src="icons.Home"
                ></v-img>
                <v-img
                  v-if="currentRoute === '/students/home'"
                  :src="icons.HomeColor"
                ></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="xl:text-sm lg:text-sm md:text-sm sm:text-sm ma-0"
                  >{{ !$language ? "Home" : "হোম" }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item-group>
              <v-list-item
                exact
                to="/students/notes"
                style="padding-left: 25px"
              >
                <v-list-item-icon style="margin-right: 16px">
                  <v-img
                    v-if="currentRoute !== '/students/notes'"
                    :src="icons.Lecture"
                  ></v-img>
                  <v-img
                    v-if="currentRoute === '/students/notes'"
                    :src="icons.LectureColor"
                  ></v-img>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="xl:text-sm lg:text-sm md:text-sm sm:text-sm ma-0"
                    >{{ "Notes" }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                exact
                to="/students/tests"
                style="padding-left: 25px"
              >
                <v-list-item-icon style="margin-right: 16px">
                  <v-img
                    v-if="currentRoute !== '/students/tests'"
                    :src="icons.Exam"
                  ></v-img>
                  <v-img
                    v-if="currentRoute === '/students/tests'"
                    :src="icons.ExamColor"
                  ></v-img>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="xl:text-sm lg:text-sm md:text-sm sm:text-sm ma-0"
                    >{{ "Tests" }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                style="padding-left: 25px"
                exact
                to="/students/announcements"
              >
                <v-list-item-action style="margin-right: 16px">
                  <v-img
                    v-if="currentRoute !== '/students/announcements'"
                    :src="icons.Announcement"
                  ></v-img>
                  <v-img
                    v-if="currentRoute === '/students/announcements'"
                    :src="icons.AnnouncementColor"
                  ></v-img>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    class="xl:text-sm lg:text-sm md:text-sm sm:text-sm"
                    >{{ !$language ? "Announcements" : "নোটিশ" }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list subheader>
            <v-list-group
              id="profile"
              v-model="profileActive"
              no-action
              class="pl-0 ma-0"
              append-icon="mdi-chevron-up"
              @click="courseActive = false"
            >
              <template v-slot:activator>
                <v-list-item class="pl-0">
                  <v-list-item-avatar
                    style="margin-right: 12px; padding: 0px; margin-left: -8px"
                    height="32"
                    width="32"
                  >
                    <user-avatar
                      style="padding: 0px 0px; margin: 0"
                      :user-name="user.name"
                      size="32"
                      color="primary"
                    ></user-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content style="padding: 0px; margin: -4px">
                    <v-list-item-title
                      class="xl:text-sm lg:text-sm md:text-sm sm:text-sm"
                      :style="profileActive ? 'color: #0080b8' : ''"
                    >
                      {{ user.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 10px"
                      >{{ user.email }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list-item
                link
                :style="mini ? 'padding-left: 8px' : 'padding-left: 25px'"
                to="/accounts"
              >
                <v-list-item-icon style="margin-right: 16px">
                  <v-img
                    v-if="currentRoute !== '/accounts'"
                    :src="icons.AccountDetails"
                  ></v-img>
                  <v-img
                    v-if="currentRoute === '/accounts'"
                    :src="icons.AccountDetailsColor"
                  ></v-img>
                </v-list-item-icon>
                <v-list-item-title
                  class="xl:text-sm lg:text-sm md:text-sm sm:text-sm"
                  >{{ "My Account" }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                style="padding-left: 25px; padding-right:0px"
                exact
                to="/students/payment-transaction"
              >
                <v-list-item-icon style="margin-right: 16px">
                  <v-img
                    v-if="currentRoute !== '/students/payment-transaction'"
                    :src="icons.Payment"
                  ></v-img>
                  <v-img
                    v-if="currentRoute === '/students/payment-transaction'"
                    :src="icons.PaymentColor"
                  ></v-img>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="xl:text-sm lg:text-sm md:text-sm sm:text-sm"
                    >{{
                      !$language
                        ? "Payments & Transactions"
                        : "পেমেন্ট এবং ট্রানজেকশন"
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                exact
                :style="mini ? 'padding-left: 8px' : 'padding-left: 25px'"
                style="margin-bottom: 10px;"
                link
                @click="$emit('logout')"
              >
                <v-list-item-action style="margin-right: 16px">
                  <v-img :src="icons.LogOut"></v-img>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    class="xl:text-sm lg:text-sm md:text-sm sm:text-sm"
                    >{{ "Log out" }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-layout>
      </slot>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import AnnouncementIcon from "@ef/app-shell/assets/ielts/sidenav/announcement.svg";
import AnnouncementIconColor from "@ef/app-shell/assets/ielts/sidenav/announcement-selected.svg";
import PaymentIcon from "@ef/app-shell/assets/ielts/sidenav/p&t.svg";
import PaymentIconColor from "@ef/app-shell/assets/ielts/sidenav/p&t-selected.svg";
import AccountDetailsIcon from "@ef/app-shell/assets/ielts/sidenav/myacc.svg";
import AccountDetailsIconColor from "@ef/app-shell/assets/ielts/sidenav/myacc-selected.svg";
import LogOutIcon from "@ef/app-shell/assets/ielts/sidenav/logout.svg";
import HomeIcon from "@ef/app-shell/assets/ielts/sidenav/home.svg";
import HomeIconColor from "@ef/app-shell/assets/ielts/sidenav/home-selected.svg";
import LectureIcon from "@ef/app-shell/assets/ielts/sidenav/lecture.svg";
import LectureIconColor from "@ef/app-shell/assets/ielts/sidenav/lecture-selected.svg";
import AssignmentIcon from "@ef/app-shell/assets/ielts/sidenav/assignment.svg";
import AssignmentIconColor from "@ef/app-shell/assets/ielts/sidenav/assignment-selected.svg";
import ProjectIcon from "@ef/app-shell/assets/ielts/sidenav/project.svg";
import ProjectIconColor from "@ef/app-shell/assets/ielts/sidenav/project-selected.svg";
import ExamIcon from "@ef/app-shell/assets/ielts/sidenav/exam.svg";
import ExamIconColor from "@ef/app-shell/assets/ielts/sidenav/exam-selected.svg";
import GradesIcon from "@ef/app-shell/assets/ielts/sidenav/grades.svg";
import GradesIconColor from "@ef/app-shell/assets/ielts/sidenav/grades-selected.svg";

import UserAvatar from "~ef/components/UserAvatar.vue";

export default {
  name: "StudentAppNavigationDrawer",
  components: { UserAvatar },

  data() {
    return {
      drawer: true,
      mini: false,
      profileActive: false,
      courseActive: false,
      myAccountActive: false,
      swichImg: true
    };
  },
  computed: {
    user() {
      return this.$store.state.userAF.userDetails;
    },
    teacherUrl() {
      return "/teachers" || "";
    },
    isTeacher() {
      return this.$store.getters["userAF/isTeacher"];
    },
    classromm_url() {
      return "/students";
    },
    smallScreen() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    tabScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    icons() {
      return {
        Announcement: AnnouncementIcon,
        AnnouncementColor: AnnouncementIconColor,
        Payment: PaymentIcon,
        PaymentColor: PaymentIconColor,
        Lecture: LectureIcon,
        LectureColor: LectureIconColor,
        Assignment: AssignmentIcon,
        AssignmentColor: AssignmentIconColor,
        Project: ProjectIcon,
        ProjectColor: ProjectIconColor,
        Exam: ExamIcon,
        ExamColor: ExamIconColor,
        Grades: GradesIcon,
        GradesColor: GradesIconColor,
        AccountDetails: AccountDetailsIcon,
        AccountDetailsColor: AccountDetailsIconColor,
        LogOut: LogOutIcon,
        Home: HomeIcon,
        HomeColor: HomeIconColor
      };
    },
    currentRoute() {
      return this.$route.fullPath;
    }
  },
  watch: {
    smallScreen(currentValue, oldValue) {
      if (!currentValue && this.mini) {
        this.mini = false;
      }
      if (currentValue && !this.mini) {
        this.mini = true;
      }
      this.$emit("collapse", this.mini);
    },
    tabScreen(currentValue, oldValue) {
      if (!currentValue && this.mini) {
        this.mini = true;
      }
    }
  },
  mounted() {
    if (this.$vuetify.breakpoint.name === "xs") {
      this.mini = true;
    }
    if (
      this.currentRoute === "/courses/?tab=enrolled" ||
      this.currentRoute === "/courses/?tab=completed"
    ) {
      this.courseActive = true;
    }
    if (this.currentRoute === "/orders") {
      this.profileActive = true;
    }
    this.$emit("collapse", this.mini);
  },
  methods: {
    collapseHandler() {
      this.mini = !this.mini;
      this.$emit("collapse", this.mini);
    },
    switchView() {
      window.location.href =
        process.env.VUE_APP_STUDENT_VIEW_URL || "https://classroom.mouchak.com";
    }
  }
};
</script>

<style lang="scss" scoped>
.v-badge__badge {
  min-width: 16px !important;
  height: 16px;
  border-radius: 12px;
  padding: 3px;
  font-size: 10px;
}

#account-section .v-list-item__icon {
  margin-left: 0px;
}

.sidenav {
  max-width: 56px;
}

#profile {
  display: flex;
  flex-direction: column-reverse;
}

@media screen and (min-width: 960px) {
  #profile .v-icon {
    margin: auto;
  }
  #courses .v-icon {
    margin: -18px;
  }
  #account-section .v-icon {
    margin: auto;
  }
}

.v-list-item__icon {
  margin: auto;
}

@media screen and (max-height: 768px) {
  .v-list-item {
    min-height: 32px;
    max-height: 42px;
  }
}

@media screen and (max-width: 960px) {
  .v-list-item {
    padding: 0 8px;
  }
}

.v-list-group__header__prepend-icon {
  display: none !important;
}

.mk-navigation-drawer {
  transition: max-width 0.6s ease;

  .v-list-item {
    border-radius: 6px !important;
    max-height: 87px;
    min-height: 52px;
  }

  .v-list-item--link::before {
    border-radius: 6px !important;
  }

  .v-list-item--link::after {
    border-radius: 6px !important;
  }

  .v-list-item--active {
    background: #d6f3ff !important;
    color: #0099dc;

    .v-icon {
      color: #0099dc !important;
    }
  }

  .v-list-item:hover {
    background: #d6f3ff;
  }

  ::v-deep .v-list-group__header:hover {
    background: #d6f3ff !important;
    border-radius: 6px;
  }
}
</style>
